<template>
  <div id="app" >
    <div class="row m-0 p-0" style="background:black;"  >
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-9 p-0 m-0 pt-2">
      <video id="mux-default" class="video-js vjs-16-9 " controls preload="auto" autoplay muted width="100%"
        poster="https://image.mux.com/oif5pppxryL01L11JrQYPbvXaRbRViHrFal02A01swZ5to/thumbnail.jpg" data-setup='{}'>
        <source src="oif5pppxryL01L11JrQYPbvXaRbRViHrFal02A01swZ5to" type="video/mux" />
      </video>
    </div>
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3 p-0 m-0 pt-2" >
      <div class="chatContainer"  >
        <router-view />
      </div>
    </div>
  </div>
   </div>
</template>
<script>
import axios from 'axios'
export default {
  name: 'App',
  metaInfo: {
    title: "Elevate24'",
    titleTemplate: "%s"
  },
  watch: {
    '$route': function (to, from) {
      this.checkLoggedIn(to.path, from)
    }
  },
  created() {
    this.checkLoggedIn(this.$router.history.current.path, '')
  },

  async mounted() {
    axios.defaults.withCredentials = true;
  },
  methods: {
    checkLoggedIn(currentPath, from) {
      if (Object.keys(localStorage).includes('userData')) {
        let token = JSON.parse(localStorage.userData).token
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }
        if (currentPath === "/") {
          this.$router.push("/chat");
        }
      }
      else {
        if (currentPath !== "/") {
          this.$router.push("/");
        }

        if (from.path)
          window.location.reload()
        else {
          setTimeout(function () {
            window.scrollTo(0, 0)
          }, 200)
        }
      }
    }
  }
}
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100vh;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.chatContainer {}
</style>
