<template>
  <div >
      <div class="container">
      <div class="row justify-content-center">
          <div class="col-md-12">
            <div class="card" style="background: none; border: 1px solid white; margin: 10px;">
                <div class="card-header text-white"    style= "font-size: 14px; display: flex; color:'#fff';  justify-content: center; font-weight: bold; padding-bottom:.3rem" >Join the conversation now</div>
                <div class="card-body card-check mt-3">
                        <div class="row mb-3" style="display:flex; justify-content:center">
                            <!-- <label for="name" class="col-md-4 col-form-label text-md-end" style="color: white;">Name</label> -->

                            <div class="col-md-11 ">
                                <input id="name" type="text" class="form-control" placeholder="Name" required autocomplete="name" autofocus v-model="userName">
                            </div>
                        </div>
                        <div class="row mb-3"  style="display:flex; justify-content:center">
                            <!-- <label for="phone" class="col-md-4 col-form-label text-md-end"     style="color: white;">Phone</label> -->
                            <div class="col-md-11">
                                <input id="phone" type="text" class="form-control"  required  placeholder="Phone" v-model="mobileNumber" >
                                <button type="submit" class="btn px-4 py-1 mt-3" style="background:#452379; color:white" @click="registerUser">
                                   Start
                                </button>
                            </div>
                        </div>
                        <!-- <div class="form-check">
                          <input class="form-check-input cursor-pointer" type="checkbox" v-model="isTerms" id="flexCheckDefault" required>
                          <label class="form-check-label text-white cursor-pointer " for="flexCheckDefault">
                            Accept <a href="https://www.lg.com/in/privacy" target="_blanck" class="text-white" >terms and conditions </a>
                          </label>
                        </div> -->
                        <!-- <div class="row mb-3">
                            <label for="email" class="col-md-4 col-form-label text-md-end">{{ __('Email Address') }}</label>

                            <div class="col-md-6">
                                <input id="email" type="email" class="form-control @error('email') is-invalid @enderror" name="email" value="{{ old('email') }}" required autocomplete="email">

                                @error('email')
                                    <span class="invalid-feedback" role="alert">
                                        <strong>{{ $message }}</strong>
                                    </span>
                                @enderror
                            </div>
                        </div>

                        <div class="row mb-3">
                            <label for="password" class="col-md-4 col-form-label text-md-end">{{ __('Password') }}</label>

                            <div class="col-md-6">
                                <input id="password" type="password" class="form-control @error('password') is-invalid @enderror" name="password" required autocomplete="new-password">

                                @error('password')
                                    <span class="invalid-feedback" role="alert">
                                        <strong>{{ $message }}</strong>
                                    </span>
                                @enderror
                            </div>
                        </div>

                        <div class="row mb-3">
                            <label for="password-confirm" class="col-md-4 col-form-label text-md-end">{{ __('Confirm Password') }}</label>

                            <div class="col-md-6">
                                <input id="password-confirm" type="password" class="form-control" name="password_confirmation" required autocomplete="new-password">
                            </div>
                        </div> -->          
                    </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Pusher from "pusher-js";
import Echo from "laravel-echo";

console.log(Pusher);

export default {
  name: "Register",

  data() {
    return {
        userName: '',
        mobileNumber: '',
        userData:{},
        isTerms: false
    };
  },

  async mounted() {
    
  },
  methods:{
      registerUser(){
          // console.log("check value", this.isTerms)
          if(this.userName=='') return false
          // if(this.mobileNumber=='') return false
          if(!this.validateMobileNumber(this.mobileNumber)|| !this.mobileNumber){
            alert("Invalid phone number")
            return false;
          }
          // if(this.isTerms==false){
          //    alert("Accept terms and conditions") 
          //    return false
          // }
          var data = new FormData();
          let self = this
          let url = 'https://api.chat.lyxelandflamingotech.in/api/register';
          data.append('name', self.userName);
          data.append('phone', self.mobileNumber);
          axios.post(url, data).then(function(res) {
            axios.defaults.headers.common['token'] = res.data.token
            localStorage.userData = JSON.stringify(res.data)
            self.$router.push("/chat");
          });
      },
      validateMobileNumber(mobile){
         var re = /^(\+\d{1,3}[- ]?)?\d{10}$/;
          return re.test(mobile);
      }
  },

};
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.card-check{
  padding: 0rem 1.2rem 0.8rem 1.2rem !important;
}
nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.cursor-pointer{
  cursor: pointer;
}
</style>
